'use client'
import Image from 'next/image'
import './page.module.styles.css'
import Link from 'next/link'
import LogoFooter from '../../image/icon/logo-desktop.svg'
import IconFacebook from '../../image/icon/facebook-f.svg'
import IconInstagram from '../../image/icon/instagram.svg'
import IconTiktok from '../../image/icon/tiktok.svg'
import IconYoutube from '../../image/icon/youtube.svg'
// import AppleStore from '../../image/icon/Download_on_the_App_Store_blk.svg'
// import GooglePlay from '../../image/icon/google-play-badge.png'
// import QRCode from '../../image/icon/qr-code.png'
const Footer = () => {
  const time = new Date()
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full bg-gray-343a py-4 sm:py-7">
        <div className="container mx-auto space-bottom">
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 lg:gap-12 xl:gap-40">
            <div className="flex flex-col w-full gap-3">
              <Image src={LogoFooter} alt="logo-footer" className="lg:w-5/12 xl:w-full" />
              <span className=" text-white text-18">
                เว็บไซต์ที่รวบรวมร้านค้าจีนจาก 1688 , Taobao , TMall มาไว้ให้คุณสามารถ Shopping
                ได้อย่างง่ายดาย “เวลาไม่พอบิน ก็ช้อปแบบฟินๆ ทุกที่ ทุกเวลา”
              </span>
            </div>

            <div className="flex w-full">
              <ul className="nav-footer">
                <li className="header">ข้อตกลงและเงื่อนไข</li>
                <li>
                  <Link href="/contents/about-us">เกี่ยวกับ ONE MALL</Link>
                </li>
                <li>
                  <Link href="/contents/privacy-policy">นโยบายความเป็นส่วนตัว</Link>
                </li>
                <li>
                  <Link href="/contents/terms-of-service">เงื่อนไขการใช้บริการ</Link>
                </li>
              </ul>
            </div>
            <div className="flex w-full">
              <ul className="nav-footer">
                <li className="header">คำถามที่พบบ่อย</li>
                <li>
                  <Link href="/">การสมัครสมาชิก</Link>
                </li>
                <li>
                  <Link href="/contents/how-to-buy">วิธีการสั่งซื้อ</Link>
                </li>
                <li>
                  <Link href="/contents/article-shipping-cost">ค่าบริการ</Link>
                </li>
              </ul>
            </div>
            <div className="flex w-full">
              <ul className="nav-footer">
                <li className="header">ติดตามเรา</li>
                <div className="list-socaial">
                  <Link
                    href="https://www.facebook.com/share/fjNkAzqwJNdfUE7Y/?mibextid=LQQJ4d"
                    className="box-socaial"
                    target="_blank"
                  >
                    <Image src={IconFacebook} alt="facebook" className="facebook" />
                  </Link>
                  <Link
                    href="https://www.instagram.com/onemall.sh0p"
                    className="box-socaial"
                    target="_blank"
                  >
                    <Image src={IconInstagram} alt="instargram" />
                  </Link>
                  <Link
                    href="https://www.tiktok.com/@onemall.sh0p/"
                    className="box-socaial"
                    target="_blank"
                  >
                    <Image src={IconTiktok} alt="tiktok" />
                  </Link>
                  <Link
                    href="https://www.youtube.com/@ONEMALLChannel/"
                    className="box-socaial"
                    target="_blank"
                  >
                    <Image src={IconYoutube} alt="youtube" />
                  </Link>
                </div>
                <li>
                  <Link href={`tel:${'065-343-4691'}`}>โทร : 065-343-4691</Link>
                </li>
                <li>ไลน์ : @Onemallshop</li>
              </ul>
            </div>
            {/* <div className="flex w-8/12  sm:w-5/12 md:w-5/12 lg:w-full xl:w-full col-span-2 lg:col-span-1 xl:col-span-1">
            <div className="flex flex-row gap-2 w-full items-start">
              <div className="flex w-5/12">
                <div className="box-qr">
                  <Image src={QRCode} alt="apple" className="w-full" />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-7/12">
                <div className="flex">
                  <Link href="#" className="w-full">
                    <Image src={AppleStore} alt="apple" className="w-full" />
                  </Link>
                </div>
                <div className="flex">
                  <Link href="#" className="w-full">
                    <Image src={GooglePlay} alt="apple" className="w-full" />
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <div className="wrap-coppy">
        <span className="pr-1">
          &copy; <time dateTime={time}>{time.getFullYear()}</time>{' '}
        </span>
        ONE MALL. All Rights Reserved
      </div>
    </div>
  )
}
export default Footer
